import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import FacebookIcon from '@material-ui/icons/Facebook'
import Link from '../link'
import Logo from '../../assets/svg/logo.svg'

export const networks = [
    {
        name: 'twitter',
        url: '',
    },
    {
        name: 'FacebookIcon',
        url: 'https://www.facebook.com/Armenaki-Katikies-108996427560310',
    },
    {
        name: 'linkedin',
        url: '',
    },
]
const Footer = () => {
    const { t } = useTranslation('links')
    return (
        <footer className="footer navbar-static-bottom">
            <Container fluid>
                <Row className="footer_contact_section">
                    <Col md={3}>
                        <Row className="d-flex align-items-center justify-content-center">
                            <div className="rest-of-logo mt-3">  <Logo className="footerLogo" />ρμενακι</div>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <h6>OUR ADRESS</h6>
                        <div>Ανάφη 840 09</div>
                        <div> Κυκλάδες</div>
                    </Col>
                    <Col md={3}>
                        <h6>RESERVATION</h6>
                        <div>Tel 1: 2286 061234</div>
                        <div>Tel 2: +30 697 714 4310</div>
                    </Col>
                    <Col md={3}>
                        <div className="social-links">
                            <h6>CONNECT</h6>
                            <a
                                href="https://www.facebook.com/Armenaki-Katikies-108996427560310"
                                target="_blank" rel="noreferrer"
                            >
                                <FacebookIcon fontSize="large" />
                            </a>
                        </div>
                    </Col>
                </Row>

                <div className="footer_bar">
                    <div className="footer_bar_wrapper">
                        <Row className="menu-footer-menu-container">
                            <Col md={6}>
                                <ul id="footer_menu" className="footer_nav">
                                    <li className="menu-item">
                                        <Link to="/">{t('home')}</Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="/">{t('rooms')}</Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="/restaurant">{t('restaurant')}</Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="/anafi">{t('anafi')}</Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="/contact">{t('contact')}</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col md={{ span: 3, offset: 3 }} id="copyright">
                                © {new Date().getFullYear()} - Armenaki{' '}
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </footer>
    )
}

export default Footer
